import React from 'react';
import { InputBox } from '../../../components';
import { linksArr } from '../../HomePage/app/topContent';
import './index.less'
import { Link } from 'react-router-dom';
import { notification } from 'antd';
import Notification from '../../../components/Notification';
import query from '../../../service';
import EmailModal from '../contactus/index'
interface navItem {
    title: string,
    child: {
        title: string,
        type: string,
        link: string
    
    }[]
}
const Footer:React.FC = () => {
    const [status,setStatus] = React.useState<boolean>(false)
    const [btnString,setBtnString] = React.useState<string>('Submit')
    const [email,setEmail] = React.useState<string>('')
    const [emailModalVisable,setEmailModalVisable] =React.useState<boolean>(false)
    const openEmailModal:()=>void = () => {
        setEmailModalVisable(true)
    }
    const closeEmailModal:()=>void = () => {
        setEmailModalVisable(false)
    }


    const routerArr:navItem[] = [
        {
            title: 'About Polarise',
            child: [
                // {
                //     title: 'News',
                //     type:'router',
                //     link: '/newscenter'
                // },
                {
                    title: 'X',
                    type:'outLink',
                    link: 'https://twitter.com/Polariseorg',
                },
                {
                    title: 'Discord',
                    type:'outLink',
                    link: 'https://discord.gg/polariseorg',
                },
                {
                    title: 'Medium',
                    type:'outLink',
                    link: 'https://medium.com/@polarise',
                },
                // {
                //     title: 'Blog (VN)',
                //     type:'outLink',
                //     link: 'https://polarisevn.medium.com/',
                // },
                // {
                //     title: 'Docs',
                //     type:'outLink',
                //     link: 'https://pawnfi.gitbook.io/docs/'
                // },
            ]
        },
        {
            title: 'Developer',
            child: [
                // {
                //     title: 'Github',
                //     type:'outLink',
                //     link: 'https://github.com/PawnFi'
                // },
                {
                    title: 'SDK (TBU)',
                    type:'outLink',
                    link: ''
                },
                {
                    title: 'Doc',
                    type:'outLink',
                    link: 'https://docs.polarise.org/'
                },
                // {
                //     title: 'ERC-1000',
                //     type:'outLink',
                //     link: ''
                // },
            ]
        },
        {
            title: 'Trade',
            child: [
                {
                    title: 'Gate.io',
                    type:'outLink',
                    link: 'https://www.gate.io/trade/PFT_USDT',
                },
                {
                    title: 'Uniswap',
                    type:'outLink',
                    link: 'https://app.uniswap.org/swap?chain=ethereum&inputCurrency=0xdAC17F958D2ee523a2206206994597C13D831ec7&outputCurrency=0x0B49707fb9706428D1bb51a2906617AEaba82346',
                },
                {
                    title: 'Opensea',
                    type:'outLink',
                    link: 'https://opensea.io/collection/polarfighters',
                },
            ]
        },
        {
            title: 'Terms of Service',
            child: [
                {
                    title: 'Terms of Use',
                    type:'router',
                    link: '/introduce'
                },
                {
                    title: 'Privacy Policy',
                    type:'router',
                    link: '/policy'
                },
            ]
        },

    ]
    const submitEmail = () => {
        if(btnString!=='Submit')return
        let checkEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!email || !checkEmail.test(email))return Notification('Pls enter a valid email address')
        query({
            name: 'adduseremail',
            data: {
              email
            }
          }).then(res => {
                setStatus(true)
                setBtnString('Success')
                // Notification('Email submitted successfully','success')

                setTimeout(()=>{
                    setStatus(false)
                    setBtnString('Submit')
                    setEmail('')
                },4000)
          }
          ).catch(err => {
            Notification('Failed to submit email')
          })
        }
    
    return (
        <div className='website-footer'>
                <div className='footer-content flex align_center justify_center'>
                        <div className='flex align_center content-center justify_between'>
                                <div className='footer-content-left'>
                                        <div className='flex column'>
                                                <span className='text-white fw_600 fs_3 mb_2'>
                                                Join Our Community
                                                </span>
                                                <span className='text-1 fs_2 sub_title'>
                                                Get the latest news
                                                </span>
                                        </div>
                                        <div className='email-input pl_2 mt_6 flex align_center'>
                                                <InputBox
                                                value={email}
                                                onChange={(e)=>{setEmail(e.target.value)}}
                                                placeholder='Enter email'
                                                />
                                                <div className='submit-button flex justify_center align_center cursor' onClick={submitEmail}>
                                                        <div className='submit-status-btn'>
                                                            <i className={`iconfont icon-icon-yep ${status&&'active'}`}></i>
                                                            <i className={`iconfont icon-icon-arrow2 ${!status&&'active'}`}></i>
                                                        </div>
                                                        <div className='fw_600 text-white'>{btnString}</div>
                                                </div>
                                        </div>
                                        <div className='flex footer-links mt_6 align_center'>
                                                {
                                                    linksArr.map((item, index) => {
                                                        return (
                                                            <a key={index} href={item.href} target="_blank" rel="noreferrer"><span className={`footer-link iconfont ${item.icon}`}></span></a>
                                                        )
                                                    }
                                                    )
                                                }
                                                <div className='text-underline text-1 fs_1 fw_600 cursor contact' onClick={openEmailModal}>
                                                    Contact Us
                                                </div>
                                        </div>
                                </div>
                                <div className='footer-content-right flex'>
                                        {
                                            routerArr.map((item, index) => (
                                                <div className='footer-link' key={index}>
                                                        <div className='text-white fw_600'>{item.title}</div>
                                                        <div className='flex column text-1 cursor fw_m'>
                                                            {
                                                                item.child.map((val, idx) => (
                                                                    <div key={idx} className='mb_3'>
                                                                        {
                                                                            val.link?
                                                                                val.type === 'router'?
                                                                                <Link to={val.link} >{val.title}</Link>
                                                                                :
                                                                                <a href={val.link} target='_blank' rel="noreferrer">{val.title}</a>
                                                                            :
                                                                                <span>{val.title}</span>
                                                                        }
                                                                    </div>
                                                                ))
                                                            }
                                                            <span></span>
                                                        </div>
                                                </div>

                                            ))
                                        }
                                </div>
                        </div>
                </div>
                {/* <div className='footer-bottom flex justify_center align_center'>
                        <div className='bottom-text text-white flex align_center'>
                            <span className='point'></span>
                            © 2021-2024 Polarise.org, Inc. All rights reserved.
                        </div>
                        <div className='mb-footer-links'>
                                {
                                    linksArr.map((item, index) => {
                                        return (
                                            <a key={index} href={item.href} target="_blank" rel="noreferrer"><span className={`mb-footer-link iconfont ${item.icon}`}></span></a>
                                        )
                                    }
                                    )
                                }

                        </div>
                </div> */}
                <EmailModal
                isModalOpen={emailModalVisable}
                handleCancel={closeEmailModal}
                
                />
        </div>
    )
}
export default Footer