import './index.less'
import { useTranslation } from 'react-i18next'
import EmailModal from '../../../layout/contactus'
import { useState } from 'react'
import { Popover } from 'antd';
interface linkItme {
    icon: string,
    href: string
}
export const linksArr:linkItme[] = [
    // {
    //     icon: 'icon-icon-gethub',
    //     href: 'https://github.com/PawnFi'
    // },
    // {
    //     icon: 'icon-icon-airplane',
    //     href: 'https://t.me/pawnfiprotocol'
    // },
    {
        icon: 'icon-icon-Twitter',
        href: 'https://twitter.com/Polariseorg'
    },
    {
        icon: 'icon-icon-r',
        href: 'https://discord.gg/polariseorg'
    },
    {
        icon: 'icon-icon-M',
        href: 'https://medium.com/@polarise'
    },
  

]

const TopContent = () => {
    const {t} = useTranslation()
    const [emailModalVisable,setEmailModalVisable] = useState<boolean>(false)
    const closeEmailModal:()=>void = () => {
        setEmailModalVisable(false)
    }
    const openEmailModal:()=>void = () => {
        setEmailModalVisable(true)
    }
    return (
        <div className="top-content flex column align_center">
                <video muted loop className="bg-video" autoPlay>
                    <source src="/images/home_vedio.mp4" type="video/mp4" />
                </video>
                <div className='content-center flex justify_center'>
                        <div className='content-left flex column justify_center align_center'>
                                <div className='content-title fw_b'>
                                    {/* <div className='text-main'>Multi-Chain, Multi-Form</div> */}
                                    <div className='text-white sub-title text_center'>
                                        The Trailblazing Multi-Chain AI Asset Solution And Liquidity Platform
                                    </div>
                                </div>
                                <div className='content-desc text-1 text_center'>
                                    AI Nodes | BRC-20 | Runes | NFT Collectibles (All EVM Chains)
                                </div>
                                <div className='flex jump-btn align_center'>
                                    {/* <Popover content={'Our app is being updated. Please stay tuned for the upcoming launch.'}> */}
                                        <div 
                                            className='bg-main launch-app fw_600 text-white cursor flex'
                                            onClick={()=>{
                                                window.open(`https://www.gate.io/trade/PFT_USDT`)
                                            }}
                                            >
                                               Trade On Gate.io
                                        </div>
                                    {/* </Popover> */}
                                     
                                        <div 
                                        className='bg-main launch-app fw_600 text-white cursor flex'
                                        onClick={()=>{
                                            window.open(`https://app.uniswap.org/swap?chain=ethereum&inputCurrency=0xdAC17F958D2ee523a2206206994597C13D831ec7&outputCurrency=0x0B49707fb9706428D1bb51a2906617AEaba82346`)
                                        }}
                                        >
                                            Trade On Uniswap
                                        </div>

                                </div>
                                <div className='content-links flex align_center'>
                                        {
                                            linksArr.map((item, index) => {
                                                return (
                                                    <a key={index} href={item.href} target="_blank" rel="noreferrer"><span className={`content-link iconfont ${item.icon}`}></span></a>
                                                )
                                            }
                                            )
                                        }
                                        <div className='fs_1 fw_600 text-white text-underline cursor contact' onClick={openEmailModal}>{t('main.pw.us')}</div>
                                </div>
                        </div>
                        {/* <div className='content-right'>
                                <iframe className='canvas3d'
                                title='logo'
                                loading='lazy'
                                src='https://my.spline.design/untitled-67ea569a1f059a9db9e1373ce4d1993c/'
                                frameBorder='0' width='100%' height='100%'>
                                </iframe>
                        </div> */}

                </div>
                {/* <div className='scroll-target text-1 flex align_center'>
                        <div className='swiper-down flex justify_center'>
                                <span className='swiper-line'></span>
                        </div>
                        <a href="#second" className='fs_1 fw_600'>{t('main.pw.down')}</a>
                </div> */}
                <EmailModal
                isModalOpen={emailModalVisable}
                handleCancel={closeEmailModal}
                />
        </div>
    )
}
export default TopContent